<template>
  <div class="history">
    <div class="flex justify-between">
      <!-- <el-input
        style="width:25%;"
        size="mini"
        placeholder="请输入店铺名称"
        v-model="keyword"
      >
        <i
          slot="suffix"
          class="el-input__icon el-icon-search cursor-pointer"
          @click="onSearch"
        ></i>
      </el-input> -->
      <!-- <div class=" cursor-pointer text-warning " @click="clearAll">
        一键清空
      </div> -->
    </div>
    <div class="relative flex-1">
      <div class="h-full">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="浏览的店铺" name="store">
            <HistoryStoreList ref="storeList" type="0" :keyword="keyword"/>
          </el-tab-pane>
          <el-tab-pane label="浏览的商品" name="goods">
            <HistoryPartsList ref="partsList" type="1" :keyword="keyword"/>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div
        class=" absolute right-8 top-8 cursor-pointer text-warning"
        @click="clearAll"
      >
        一键清空
      </div>
    </div>
  </div>
</template>

<script>
import HistoryPartsList from "./components/historyPartsList.vue";
import HistoryStoreList from "./components/historyStoreList.vue";
export default {
  name: "history",
  components: {
    HistoryPartsList,
    HistoryStoreList,
  },
  data() {
    return {
      activeName: "store",
      keyword:''
    };
  },
  mounted() {
    this.handleClick({ name: "store" });
  },
  methods: {
    onSearch() {
      setTimeout(() => {
        this.handleClick({ name: this.activeName });
      }, 200);
    },
    handleClick(tab) {
      switch (tab.name) {
        case "store":
          if (this.$refs.storeList) this.$refs.storeList.loadList();
          break;
        case "goods":
          if (this.$refs.partsList) this.$refs.partsList.loadList();
          break;
      }
    },
    clearAll(){
      switch (this.activeName) {
        case "store":
          if (this.$refs.storeList) this.$refs.storeList.clearAllFn();
          break;
        case "goods":
          if (this.$refs.partsList) this.$refs.partsList.clearAllFn();
          break;
      }
    }
  },
};
</script>

<style lang="less" scoped>
.history {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .relative {
    overflow: hidden;
    /deep/.el-tabs {
      height: 100%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      .el-tabs__content {
        flex: 1;
        .el-tab-pane {
          height: 100%;
        }
      }
    }
  }
}
</style>
