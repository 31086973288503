import request, { SERVICE_MODULE_MAP } from "@/utils/request";

//我的浏览商品列表
/**
  "keyword": "",	 # 店铺名称
  "page": 0,	 # 页码
  "pageSize": 0	 # 页宽
*/
export function getBrowseGoodsListApi(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/relations/browse-goods/list",
    method: "get",
    params
  });
}

//删除浏览商品
/*
    "all": false,	 # 是否删除全部/清空
  "id": 0	 # 浏览记录ID
*/
export function deleteBrowseGoodsApi(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/relations/browse-goods/delete",
    method: "post",
    data
  });
}

//我的浏览店铺列表
/**
  "keyword": "",	 # 店铺名称
  "page": 0,	 # 页码
  "pageSize": 0	 # 页宽
*/
export function getBrowseStoresListApi(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/relations/browse-stores/list",
    method: "get",
    params
  });
}

//删除浏览店铺
/*
    "all": false,	 # 是否删除全部/清空
    "id": 0	 # 浏览记录ID
  */
export function deleteBrowseStoresApi(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/relations/browse-stores/delete",
    method: "post",
    data
  });
}
