<template>
  <div class="historyPartsList">
    <!-- <div class="flex items-center mb-8">
      <el-checkbox v-model="filterOff" @change="removedShelfChange"
        >屏蔽已下架的商品</el-checkbox
      >
      <el-checkbox v-model="filterNoPrice" @change="removedShelfChange"
        >只看有价格的商品</el-checkbox
      >
      <el-checkbox v-model="filterNoStock" @change="removedShelfChange"
        >只看有库存的商品</el-checkbox
      >
    </div> -->

    <div class="content">
      <template v-for="(item, index) in list">
        <div :key="index">
          <div class="date_item">
            <i class="iconfont icon-line_record font-size-12 m-r-4"></i>
            <span>{{ item.date }}</span>
          </div>
          <template v-for="(item2, index2) in item.list">
            <GoodsItem :dataObj="item2.goodsInfo" :key="index2">
              <template #btn>
                <!-- <div class="flex flex-center"> -->
                <span
                  class=" cursor-pointer text-warning"
                  @click.stop="collectionFn(item2)"
                  v-if="!item2.goodsInfo.collected"
                >
                  收藏
                </span>
                <!-- 联系人 -->
                <ChatDialog :storeId="item2.storeId" :goodsInfo="item2.goodsInfo" @click.stop.native />

                <i
                  class="iconfont icon-line_home  icon_btn"
                  @click.stop="toStore(item2)"
                ></i>
                <i
                  class="iconfont icon-line_delete  icon_btn_del"
                  @click.stop="deleteFn(item2)"
                ></i>
                <!-- </div> -->
              </template>
            </GoodsItem>
          </template>
        </div>
      </template>
      <div class="text-center text-black-200" v-if="!list.length">暂无数据</div>
    </div>

    <el-pagination
      class="mt-8"
      style="text-align:right;"
      layout="total,prev, pager, next"
      :current-page="pageInfo.page"
      :page-size="pageInfo.pageSize"
      :total="pageInfo.totalSize"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>
</template>

<script>
import GoodsItem from "@/components/userCenter/goodsItem.vue";
import ChatDialog from "../../components/chatDialog.vue";
import { getBrowseGoodsListApi, deleteBrowseGoodsApi } from "@/api/history";
import { addCollectibleGoodsApi } from "@/api/collection";

export default {
  name: "historyPartsList",
  props: {
    keyword: String,
  },
  components: {
    GoodsItem,
    ChatDialog,
  },
  data() {
    return {
      pageInfo: {
        page: 1,
        pageSize: 20,
        totalSize: 0,
      },
      list: [],
      filterOff: false, //屏蔽已下架的商品
      filterNoPrice: false, //只看有价格的商品
      filterNoStock: false, //只看有库存的商品
    };
  },
  methods: {
    toStore(item) {
      let obj = {
        path: "/home/store",
        query: {
          storeId: item.storeId,
        },
      };
      this.$router.push(obj);
    },
    removedShelfChange() {
      this.loadList();
    },
    loadList() {
      let params = {
        page: this.pageInfo.page,
        pageSize: this.pageInfo.pageSize,
        keyword: this.keyword,
        // filterOff: this.filterOff,
        // filterNoPrice: this.filterNoPrice,
        // filterNoStock: this.filterNoStock,
      };

      getBrowseGoodsListApi(params).then((res) => {
        if (res) {
          this.list = res.rows || [];
          this.pageInfo.totalSize = res.totalSize || 0;
        }
      });
    },
    handleSizeChange(val) {
      this.pageInfo.pageSize = val;
      this.pageInfo.page = 1;
      this.loadList();
    },
    handleCurrentChange(val) {
      this.pageInfo.page = val;
      this.loadList();
    },
    collectionFn(item) {
      const params = {
        goodsId: item.goodsId,
        storeId: item.storeId,
      };
      addCollectibleGoodsApi(params).then(() => {
        this.$message.success("收藏成功");
        this.loadList(true);
      });
    },
    clearAllFn() {
      let _this = this;
      _this
        .$confirm("确定要删除所有浏览商品?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          const params = {
            all: true,
          };
          _this.delHistoryStoresFn(params);
        })
        .catch(() => {
          // on cancel
        });
    },
    deleteFn(item) {
      let _this = this;
      _this
        .$confirm("确定要删除当前浏览商品?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          const params = {
            all: false,
            id: item.id,
          };
          _this.delHistoryStoresFn(params);
        })
        .catch(() => {
          // on cancel
        });
    },
    delHistoryStoresFn(params) {
      deleteBrowseGoodsApi(params)
        .then(() => {
          this.$message.success("删除成功");
          this.loadList(true);
        })
        .catch((err) => {
          if (err && err.message) {
            this.$message.error(err.message);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.historyPartsList {
  height: 100%;
  display: flex;
  flex-direction: column;
  .content {
    flex: 1;
    overflow: auto;
    .date_item {
      margin-bottom: 4px;
      font-size: 12px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
    }
    .icon_btn {
      padding: 4px;
      background: #ffeeeb;
      border-radius: 50%;
      font-size: 16px;
      color: #ff6e4c;
      cursor: pointer;
    }
    .icon_btn_del {
      padding: 4px;
      background: #f4f4f4;
      border-radius: 50%;
      font-size: 16px;
      color: #999999;
      cursor: pointer;
    }
  }
}
</style>
